import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import parse from 'html-react-parser'
import ReactMarkdown from 'react-markdown'
import AccountLogin from '../components/AccountLogin'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const CREDITCARDS = gql`
  query getCreditCards {
    creditCard {
      data {
        attributes {
          Hero {
            Title
            Description
            ButtonURL
            ButtonTitle
            BackgroundImage {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
          Title
          Description
          CreditCards {
            id
            Title
            Description
            ButtonURL
            ButtonTitle
            Image {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
          CardCTAsTitle
          CardCTAs {
            id
            Title
            Description
            ButtonURL
            ButtonTitle
            Image {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }                
          FullWidthCTA {
            Title
            Date
            Description
            ButtonTitle
            ButtonURL
            BackgroundImage {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
          DisclaimerLinksTitle
          DisclaimerLinks {
            LinkText
            LinkUrl
          }
        }
      }
    }
  }
`
export default function CreditCards() {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.signalintent.com/js/embedded.js?org-guid=4159706a-6c26-49d4-bfac-58d685253c89';
    script.onload = () => setIsScriptLoaded(true);
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (isScriptLoaded) {
      setTimeout(() => {
        if (window.Chimney?.calculators?.createCalc) {
          window.Chimney.calculators.createCalc('calculators', '992bb347-c05f-4ea6-aa25-aaa0352409e2');
        }
      }, 1000); // Adjust the delay as necessary
    }
  }, [isScriptLoaded]);
  //const { loading, error, data } = useFetch('http://localhost:1337/api/image-ctas')
  const { data } = useQuery(CREDITCARDS)

  // if (loading) return <p>Loading...</p>
  // if (error) return <p>Error :(</p>

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  console.log(data)
  return (
    <main className='wrapper merchant' id='main' tabindex="-1">
      {data && (
        <>
        <div className='hero-banner'>
          <div className='hero bg-center' style={{backgroundImage: `url(${data.creditCard.data.attributes.Hero.BackgroundImage.data[0].attributes.url})`}}>
              <div className='grad-overlay'></div>
              <div className='inner-container'>
                <AccountLogin />
                <div className='inner-hero'>
                    <h1>{data.creditCard.data.attributes.Hero.Title}</h1>
                    <hr className='orange'></hr>
                    {data.creditCard.data.attributes.Hero.Description !== null &&
                      <p>{data.creditCard.data.attributes.Hero.Description}</p>
                    }
                    {data.creditCard.data.attributes.Hero.ButtonTitle !== null &&
                      <div className='btn-green'><Link to={data.creditCard.data.attributes.Hero.ButtonURL}>{data.creditCard.data.attributes.Hero.ButtonTitle}</Link></div>
                    }
                </div>
              </div>
          </div>
      </div>
      <div className='credit-cards cta-box container mg-top-80'>
          {data.creditCard.data.attributes.CreditCards.map((cta) => (
              <div key={cta.id} className='cta'>
                  <div className='cta-image' style={{backgroundImage: `url(${cta.Image.data.attributes.url})`}}></div>
                  <div className='cta-info'>
                      <div className='title'><h2>{cta.Title}</h2></div>
                      <div className='description'>
                        <ReactMarkdown children={cta.Description}></ReactMarkdown>
                      </div>
                  </div>
                  {cta.ButtonTitle !== null &&
                    <div className='btn-green'><Link reloadDocument to={cta.ButtonURL}>{cta.ButtonTitle}</Link></div>
                  }                  
              </div>
          ))}
      </div>
      <div className='ctas mg-top-80 col-2'>
        <h2 className='center thin-text'>{parse(data.creditCard.data.attributes.CardCTAsTitle)}</h2>
        <div className='cta-box container mg-top-40'>
            {data.creditCard.data.attributes.CardCTAs.map((cta) => (
                <div key={cta.id} className='cta'>
                    <div className='cta-image' style={{backgroundImage: `url(${cta.Image.data.attributes.url})`}}></div>
                    <div className='cta-info'>
                        <div className='title'><h2>{cta.Title}</h2></div>
                        <div className='description'><p>{parse(cta.Description)}</p></div>
                        {cta.ButtonTitle !== null &&
                          <div className='btn-ghost-green'><Link reloadDocument to={cta.ButtonURL}>{cta.ButtonTitle}</Link></div>
                        }
                    </div>
                </div>
            ))}
        </div>
      </div>
      <div className='full-width-cta mg-top-80 relative' style={{backgroundImage: `url(${data.creditCard.data.attributes.FullWidthCTA.BackgroundImage.data.attributes.url})`}}>
        <div className='overlay'></div>
        <div className='inner-content max-500 mg-auto z-index-1'>
          <h3 className='white'>{data.creditCard.data.attributes.FullWidthCTA.Title}</h3>
          <div className='white mg-top-20'><p>{parse(data.creditCard.data.attributes.FullWidthCTA.Description)}</p></div>
          {data.creditCard.data.attributes.FullWidthCTA.ButtonTitle !== null &&
            <div className='white mg-top-20'>
              <div className='btn-empty-round'>
                <Link reloadDocument to={data.creditCard.data.attributes.FullWidthCTA.ButtonURL}>
                    {data.creditCard.data.attributes.FullWidthCTA.ButtonTitle}
                </Link>
              </div>
            </div>
          }
        </div>
      </div>
      <div className='disclaimer-links mg-tb-50'>
        <div class='container'>
          <p className='max-800 mg-auto center'><b>{parse(data.creditCard.data.attributes.DisclaimerLinksTitle)}</b></p>
          <ul className='link-list'>
              {data.creditCard.data.attributes.DisclaimerLinks.map((link) => (
                  <li><Link reloadDocument to={link.LinkUrl}>{link.LinkText}</Link></li>
              ))}
          </ul>
        </div>
      </div>      
        </>
      )}
    </main>
  )
}
