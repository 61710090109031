import React, { useState } from 'react';
// import { useFormik } from 'formik';
import { useMutation, gql } from '@apollo/client';

// import { CREATE_CONTACT_MUTATION } from '../graphql/mutations';
const CREATE_EMAIL_MUTATION = gql`
  mutation CreateEmail($firstName: String!, $lastName: String!, $email: String!) {
    createEmailOptIn(data: { FirstName: $firstName, LastName: $lastName, Email: $email }) {
      data {
        id 
        attributes {
          FirstName,
          LastName,
          Email
        }
      }
    }
  }
`
export default function EmailForm() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [createEmailEntry] = useMutation(CREATE_EMAIL_MUTATION);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { firstName, lastName, email } = event.target.elements;

    try {
      await createEmailEntry({ variables: { firstName: firstName.value, lastName: lastName.value, email: email.value } });
      // Handle success - maybe clear the form or show a success message
      setFormSubmitted(true);
    } catch (error) {
      // Handle error
    }
  };

  if (formSubmitted) {
    return <div><p className='white'>Thank you for your submission!</p></div>;
  }

  return (
    <div>
        <form onSubmit={handleSubmit}>
              <input
                id="firstName"
                name="firstName"
                type="text"
                placeholder="First Name"
              />
              <input
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Last Name"
              />              
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Email Address"
              />
            <button type="submit">Sign Up</button>
        </form>
    </div>
  )
}